export default function Angle() {
  return (
    <>
      <h1>Good luck</h1>
      <iframe
        src="https://angle.wtf/"
        title="iframe Example 1"
        width="1000"
        height="700"
      ></iframe>{" "}
    </>
  );
}
