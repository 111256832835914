export default function Flagle() {
  return (
    <>
      <h1>Good luck</h1>
      <iframe
        src="https://flagle-game.com/"
        title="iframe Example"
        width="1000"
        height="800"
      ></iframe>{" "}
    </>
  );
}
