export default function Home() {
  return (
    <>
      <iframe
        src="https://i.gifer.com/7Uz.gif"
        title="dancing"
        height="400"
        width="400"
      ></iframe>
    </>
  );
}
