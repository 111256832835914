import images from "./nima.png";

export default function About() {
  return (
    <>
      <h1>About</h1>
      <div>
        <img src={images} alt="" height="20%" width="20%" />
      </div>
      <p>Text is comming soon... or does it</p>
    </>
  );
}
