export default function Bandle() {
  return (
    <>
      <h1>Good luck!</h1>
      <iframe
        src="https://bandle.app"
        title="iframe Example 1"
        width="1000"
        height="700"
      ></iframe>{" "}
    </>
  );
}
