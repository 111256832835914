import "./App.css";
import Navbar from "./Navbar";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Bandle from "./Pages/Bandle";
import Flagle from "./Pages/Flagle";
import Angle from "./Pages/Angle";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Navbar />{" "}
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/bandle" element={<Bandle />}></Route>
          <Route path="flagle" element={<Flagle />}></Route>
          <Route path="/angle" element={<Angle />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="*" element={<Home />}></Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
